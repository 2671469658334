import mobileEq from '../../../assets/pics/homepage/mobileEq.webp'
import desktopEq from '../../../assets/pics/homepage/desktopEq.webp'
import { Link } from 'react-router-dom'
import './topSection.css'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'

function TopSection() {
  const currentLnag = getLang(), {companyIntro} = lang[currentLnag].home;

  function navigate (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>){
    const targetElement = document.getElementById('ourServices');
    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - 100,
        behavior: 'smooth',
      });
    }
  }
  
  return (
    <div className='intro'>
        <div className='imWra'>
            <img src={mobileEq} alt='mobileEq' className='mobileEq'/>
            <img src={desktopEq} alt='desktopEq' className='desktopEq'/>
        </div>
        <div className='introTextWra'>
            <p className=''>
              {companyIntro.text1} <br/>
                <span> {companyIntro.text2} <br/></span>
                {companyIntro.text3}
            </p>
            <Link to="#ourServices" onClick={(e) => navigate(e)} className='bgBlue'>{companyIntro.button}</Link>
        </div>
    </div>
  )
}

export default TopSection