import React, { useEffect, useRef } from 'react';
import emailIcon from '../../../../assets/pics/auth/email.svg';
import { validateEmail } from '../../../../utils/functions/validation';
import { EmailInputProps } from '../../../../models/auth/auth';

function EmailInput(props: EmailInputProps) {
  const { email, setEmail, setEmailError, emailError, inputRef } = props;
  
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);


  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    setEmailError(
      !validateEmail(event.target.value) ? 'Please enter a valid email address' : ''
    );
  }

  return (
    <div>
      {emailError && <p className="errorText">{emailError}</p>}
      <div className='input'>
        <img src={emailIcon} alt='email' className='icon' />
        <input
          type="text"
          value={email}
          onBlur={handleBlur}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          id="email"
          placeholder='E-mail'
          ref={emailInputRef}
        />
      </div>
    </div>
  );
}

export default EmailInput;
