import { useEffect, useState } from 'react'
import DepartmentInfo from '../../components/applyForm/departmentInfo/DepartmentInfo'
import JobForm from '../../components/applyForm/jobForm/JobForm'
import './applyForm.css'
import { ApplyForAJobState } from '../../models/applyForm'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../api/crudServices'
import { getLang } from '../../utils/functions/authCheck'
import lang from '../../utils/lang/lang'

function ApplyForm() {
  const [data, setData] = useState<ApplyForAJobState | null>(null)
  const {id} = useParams(), currentLnag = getLang(), {jobForm} = lang[currentLnag]
  useEffect(()=>{
    async function fetchJob(){
      const res = await fetchData(`user/job/show/${id}`)
      setData(res.data);
    }
    fetchJob()
  },[])

  if(!data)
    return <></>

  return (
    <div className='applyForm'>
      <div className="paper">
        <div className='jobHeader'>
          <h3 className='blueBox'>{jobForm.label}</h3>
          <div className='centeredText'>
            <h2 className='header2'>{data.name}</h2>
            <p className='secIntro'>
              {data.description}
            </p>
          </div>
        </div>
        <DepartmentInfo data={data}/>
        <JobForm/>
      </div>
    </div>
  )
}

export default ApplyForm