import bloodType from '../../../assets/pics/profile/bloodType.svg'
import calendar from '../../../assets/pics/calendar.svg'
import profilePic from '../../../assets/pics/profilePic.webp'
import './profileInfo.css'
import { useAppSelector } from '../../../RTK/hooks/hooks'
import lang from '../../../utils/lang/lang'
import { getLang } from '../../../utils/functions/authCheck'


function ProfileInfo() {
  const user = useAppSelector(state=> state.user)
  const currentLang = getLang(), {profile} = lang[currentLang]

  
  return (
    <div className='profileInfo paper'>
        {user.user?.image ? 
            <img src={user.user.image} alt="profilePic" /> :
            <svg viewBox="0 0 28 28" className='bg-dodger-blue bg-opacity-80' xmlns="http://www.w3.org/2000/svg">
                <path d="M20.7735 17.31C20.9093 17.3907 21.0768 17.4854 21.2666 17.5925C22.0982 18.0616 23.3553 18.7708 24.2166 19.6137C24.7552 20.1409 25.2669 20.8357 25.36 21.6869C25.4589 22.592 25.064 23.4414 24.2719 24.1961C22.9051 25.4981 21.265 26.5416 19.1437 26.5416H8.85573C6.73437 26.5416 5.09428 25.4981 3.7276 24.1961C2.93541 23.4414 2.54054 22.592 2.63948 21.6869C2.73252 20.8357 3.24428 20.1409 3.78289 19.6137C4.64409 18.7708 5.90118 18.0616 6.7328 17.5925C6.92257 17.4854 7.0902 17.3907 7.2259 17.31C11.3723 14.841 16.6272 14.841 20.7735 17.31Z" fill="white"/>
                <path d="M7.87476 7.58337C7.87476 4.20062 10.617 1.45837 13.9997 1.45837C17.3825 1.45837 20.1247 4.20062 20.1247 7.58337C20.1247 10.9661 17.3825 13.7084 13.9997 13.7084C10.617 13.7084 7.87476 10.9661 7.87476 7.58337Z" fill="white"/>
            </svg>
        }
        <div className='medicalRecord'>
            <div className='patientName'>
                <h2 className='header2'>{user.user?.name  || 'Unknown'}</h2>
                <h4>{user.birthdate}</h4>
            </div>
            <p className=''>{user.phone}</p>
            <div className='medicalInfo'>
                <div>
                    <img src={bloodType} alt="icon" className='iconWithBlueBg'/>
                    <span>{profile.personCard[0]} {user.blood_type || 'Unknown'}</span>
                </div>
                <div>
                    <img src={calendar} alt="icon" className='iconWithBlueBg'/>
                    <span>{profile.personCard[1]} {user.user?.last_appointment?.date || 'Unknown'}</span>
                </div>
            </div>
            <p className='patientStatus whitespace-pre-wrap'>
                {user.description}
            </p>
        </div>
    </div>
  )
}

export default ProfileInfo