import { useEffect, useState } from 'react'
import { YourAppointments } from '../../../../models/profile'
import BookingsCard from '../bookingsCard/BookingsCard'
import { fetchData } from '../../../../api/crudServices'

function Cancelled() {
  const [yourAppointments, setYourAppointments] = useState<YourAppointments[]>([])

  useEffect(()=>{
    async function fetchCancelled(){
      const res = await fetchData('user/appointment/getUserAppointments?status=cancelled')
      setYourAppointments(res.data)
    }
    fetchCancelled()
  },[])
  return (
    <div className='bookingsSec'>
      {yourAppointments.map((item)=><BookingsCard appointment={item} key={item.id}/>)}
    </div>
  )
}

export default Cancelled