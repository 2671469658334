import StarRating from '../starRating/StarRating'
import avatar from '../../../../assets/pics/homepage/avatar.webp'
import './ratingCard.css'
import { AllRating } from '../../../../models/ourServices'

function RatingCard(props:AllRating) {
  const {user, rating, feedback, doctor} = props
  return (
    <div className='ratingCard'>
      <div className='rater'>
        <div>
          <img src={user.image} alt='avatar'/>
          <span>{user.name}</span>
        </div>
        <p>{doctor.name}</p>
      </div>
      <p>{feedback}</p>
      <div className='stars'>
        <StarRating rating={Number(rating)} h={'18'} w={'18'}/>
        <p>{rating}</p>
      </div>
    </div>
  )
}

export default RatingCard