import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../../../models/auth/RTKAuth';
import { fetchData } from '../../../api/crudServices';

const initialState: UserState = {
    id: null,
    father_name: null,
    mother_name: null,
    birthdate: null,
    gender: null,
    description: null,
    blood_type: null,
    phone: null,
    user: null,
    doctor: null,
};

export const getProfileRequest = createAsyncThunk(
  'user/getProfileRequest', 
  async (_,{ rejectWithValue }) => {
      try {
          const res = await fetchData('user/record/getUserRecords');
          return res.data;
      } catch (error: any) {
          const errorMessage = error?.response?.data?.error || 'Something went wrong';
          return rejectWithValue(errorMessage);
      }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: (state) => {
      return {...state, ...initialState}
    },
  },
  extraReducers:(builder) => {
    builder.addCase(getProfileRequest.fulfilled, (state, action) => {
      return { ...state, ...action.payload[0] };
    })
  }
});

export const { resetUserState } = userSlice.actions;
export default userSlice.reducer;