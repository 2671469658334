import TopSection from '../../components/homePage/topSection/TopSection'
import OurVision from '../../components/homePage/ourVision/OurVision'
import OurServices from '../../components/homePage/ourServices/OurServices'
import './homepage.css'
import TopProviders from '../../components/homePage/topProviders/TopProviders'
import Blog from '../../components/homePage/blog/Blog'
import Rating from '../../components/homePage/rating/Rating'
import { useEffect, useState } from 'react'

function HomePage() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 767);

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth > 795);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='homepage'>
        <TopSection/>
        <OurVision/>
        <OurServices/>
        <TopProviders isWideScreen={isWideScreen}/>
        <Blog/>
        <Rating isWideScreen={isWideScreen}/>
    </div>
  )
}

export default HomePage