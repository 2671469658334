import { Link } from 'react-router-dom'
import star from '../../../../assets/pics/homepage/star.svg'
import { ProviderCardProps } from '../../../../models/homepage'
import './providerCard.css'
import { getLang } from '../../../../utils/functions/authCheck'

function ProviderCard(props: ProviderCardProps) {
  const {item} = props
  let count = Object.values(item.rating_counts).reduce((sum, value) => sum + value, 0);

  return (
    <div className='providerCard'>
      <img src={item.image} alt='provider'/>
      <div className='providerCardColFlex'>
        <div className='providerCardText'>
          <h4>{item.name}</h4>
          <div className='providerRating'>
            <img src={star} alt='star'/>
            {item.average_rating}
            <p>({count} reviews)</p>
          </div>
          <div className='providerServices'>
            <span className="providerService">{item.service.name}</span>
          </div>
        </div>
        <Link to={`/ourservices/service/2?DI=${item.id}`} className='bookServiceLink'>{getLang() === 'en' ? 'Book service' : 'احجز الخدمة'}</Link>
      </div>
    </div>
  )
}

export default ProviderCard