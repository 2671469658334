import { PayProps } from '../../../../models/bookAService'
import paypal from '../../../../assets/pics/bookAService/paypal.svg'
import visa from '../../../../assets/pics/bookAService/visa.svg'
import lang from '../../../../utils/lang/lang'
import { getLang } from '../../../../utils/functions/authCheck'
import './pay.css'
import Checkout from '../checkout/Checkout'
import { useState } from 'react'

function Pay(props:PayProps) {
  const {handleEnd, setAppointment, appointment} = props
  const currentLang = getLang(), {bookings} = lang[currentLang]
  const [open, setOpen] = useState(false)

  const paymentMethods = [
    { id: 'paypal-1', name: 'Paypal', icon: <img src={paypal} alt='icon'/> },
    { id: 'visa', name: 'Visa card', icon: <img src={visa} alt='icon'/> },
  ]
  return (
    <div className='pay step hideScrollBar'>
      <h4 className='importantText'>{bookings.pay}</h4>
      <div className="payRadioGroup">
          {paymentMethods.map((method) => (
            <label
              htmlFor={`paymentMethod-${method.id}`}
              key={method.id}
            >
              <div className="paymentMethodInfo">
                {method.icon}
                <span>{method.name}</span>
              </div>
              <input
                id={`paymentMethod-${method.id}`}
                type="radio"
                name="paymentMethod"
                value={method.id}
                checked={appointment.paymentMethod === method.id}
                onChange={() => setAppointment(pre => {return{...pre, paymentMethod: method.id}})}
              />
            </label>
          ))}
        </div>

      <button
        className="confirmButton"
        onClick={() => {
          setOpen(true)
        }}
      >
        {bookings.confirm}
      </button>
      <Checkout open={open} setOpen={setOpen} handleEnd={handleEnd}/>
    </div>
  )
}

export default Pay