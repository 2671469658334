import { CommentProps } from '../../../../models/blog'
import './comment.css'

function Comment(props:CommentProps) {
  const {user, created, comment} = props
  return (
    <div className='comment'>
        <div className='commentTag'>
            <img src={user.image} alt="pic"/>
            <div>
                <span>{user.name}</span>
                <p className='duration'>{created}</p>
                <p className='commentContent'>{comment}</p>
            </div>
        </div>
    </div>
  )
}

export default Comment