
import { useState, useRef, useEffect } from 'react';
import { DropdownMenuProps } from '../../../models/AppBar';
import { Link, useNavigate } from 'react-router-dom';
import './dropDown.css'
import { useAppDispatch } from '../../../RTK/hooks/hooks';
import { resetUserState } from '../../../RTK/features/userSlice/userSlice';
import { getLang } from '../../../utils/functions/authCheck';

function DropDown(props:DropdownMenuProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch()
    const {user} = props, navigate = useNavigate(), currentLang= getLang();
    
    useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) 
            setIsOpen(false);
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    }, []);
        
    return (
        <div className="dropDown" ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)}>
                {
                    user?.user?.image ? <img src={user?.user?.image} alt="pic" className='pic'/> :
                    <svg viewBox="0 0 28 28" className='pic' xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.7735 17.31C20.9093 17.3907 21.0768 17.4854 21.2666 17.5925C22.0982 18.0616 23.3553 18.7708 24.2166 19.6137C24.7552 20.1409 25.2669 20.8357 25.36 21.6869C25.4589 22.592 25.064 23.4414 24.2719 24.1961C22.9051 25.4981 21.265 26.5416 19.1437 26.5416H8.85573C6.73437 26.5416 5.09428 25.4981 3.7276 24.1961C2.93541 23.4414 2.54054 22.592 2.63948 21.6869C2.73252 20.8357 3.24428 20.1409 3.78289 19.6137C4.64409 18.7708 5.90118 18.0616 6.7328 17.5925C6.92257 17.4854 7.0902 17.3907 7.2259 17.31C11.3723 14.841 16.6272 14.841 20.7735 17.31Z" fill="currentColor"/>
                        <path d="M7.87476 7.58337C7.87476 4.20062 10.617 1.45837 13.9997 1.45837C17.3825 1.45837 20.1247 4.20062 20.1247 7.58337C20.1247 10.9661 17.3825 13.7084 13.9997 13.7084C10.617 13.7084 7.87476 10.9661 7.87476 7.58337Z" fill="currentColor"/>
                    </svg>
                }
                <span className='mr-2 rtl:ml-2 rtl:mr-0'>{user?.user?.name?.split(' ')[0] || "User"}</span>
                <svg height='20' width='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>
    
            {isOpen && (
                <div className="dropDownMenu">
                    <Link to="/profile">{currentLang == 'en' ? 'Profile' : 'الملف الشخصي'} </Link>
                    <div className="px-4 py-2">
                    <button
                        onClick={() => {
                            navigate('/login')
                            dispatch(resetUserState())
                            localStorage.removeItem('authToken')
                            sessionStorage.removeItem('authToken')
                        }}
                    >
                        {currentLang == 'en' ? 'Logout' : 'تسجيل الخروج'}
                    </button>
                    </div>
                </div>
            )}
        </div>
    );
};    
export default DropDown