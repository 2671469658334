import { StarRatingProps } from '../../../../models/bookAService';
import './starRating.css'

function StarRating({ rating, w, h } :StarRatingProps ) {

  return (
    <div className='starRating'>
      {Array.from({ length: 5 }, (_, index) => {
        const fillPercentage = Math.min(Math.max(rating - index, 0), 1) * 100;

        return (
          <div key={index} className='plainStar'>
            <svg width={w} height={h} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                stroke='#F7B217'
                d="M7.89799 1.46183C8.44374 0.401394 9.97357 0.401394 10.5193 1.46183L12.3581 5.03511C12.3886 5.0944 12.4459 5.13567 12.5122 5.14613L16.5092 5.77654C17.6947 5.96353 18.1671 7.40217 17.3193 8.244L14.458 11.0852C14.4106 11.1323 14.3887 11.1989 14.3992 11.2646L15.0299 15.23C15.2168 16.4052 13.9794 17.2948 12.9091 16.7547L9.30407 14.936C9.24416 14.9057 9.17316 14.9057 9.11324 14.936L5.50827 16.7547C4.43787 17.2948 3.20052 16.4052 3.38743 15.23L4.01811 11.2646C4.02855 11.1989 4.00671 11.1323 3.95932 11.0852L1.09797 8.244C0.250266 7.40217 0.722666 5.96353 1.90817 5.77654L5.90505 5.14613C5.97141 5.13567 6.02872 5.0944 6.05923 5.03511L7.89799 1.46183Z"
              />
            </svg>
            <div className='goldStar' style={{width: `${fillPercentage}%`}}>
              <svg width={w} height={h} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  stroke='#F7B217'
                  d="M7.89799 1.46183C8.44374 0.401394 9.97357 0.401394 10.5193 1.46183L12.3581 5.03511C12.3886 5.0944 12.4459 5.13567 12.5122 5.14613L16.5092 5.77654C17.6947 5.96353 18.1671 7.40217 17.3193 8.244L14.458 11.0852C14.4106 11.1323 14.3887 11.1989 14.3992 11.2646L15.0299 15.23C15.2168 16.4052 13.9794 17.2948 12.9091 16.7547L9.30407 14.936C9.24416 14.9057 9.17316 14.9057 9.11324 14.936L5.50827 16.7547C4.43787 17.2948 3.20052 16.4052 3.38743 15.23L4.01811 11.2646C4.02855 11.1989 4.00671 11.1323 3.95932 11.0852L1.09797 8.244C0.250266 7.40217 0.722666 5.96353 1.90817 5.77654L5.90505 5.14613C5.97141 5.13567 6.02872 5.0944 6.05923 5.03511L7.89799 1.46183Z"
                  fill="#F7B217"
                />
              </svg>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;
