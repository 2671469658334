import Layout from './components/sharedComponents/layout/Layout';
import LoginLayout from './pages/auth/login/LoginLayout';
import SignupLayout from './pages/auth/signup/SignupLayout';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from './RTK/app/store'
import { Toaster } from 'react-hot-toast';
import Homepage from './pages/homePage/HomePage';
import OurServices from './pages/ourServices/OurServices';
import Blog from './pages/blog/Blog';
import ReadMore from './pages/readMore/ReadMore';
import BookAService from './pages/bookAService/BookAService';
import Profile from './pages/profile/Profile';
import Completed from './components/profile/nestedComponents/completed/Completed';
import OnQueue from './components/profile/nestedComponents/onQueue/OnQueue';
import Cancelled from './components/profile/nestedComponents/cancelled/Cancelled';
import ApplyForAJob from './pages/applyForAJob/ApplyForAJob';
import ApplyForm from './pages/applyForm/ApplyForm';

function App() {
  return (
    <>
    <Toaster/>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/login" element={<LoginLayout/>}/>
          <Route path="/register" element={<SignupLayout/>}/>
          <Route path="/" element={<Layout/>}>
            <Route path='/' element={<Homepage/>}/>
            <Route path='/ourservices/:id' element={<OurServices/>}/>
            <Route path="/ourservices/service/:id" element={<BookAService />}/>
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<ReadMore />} />
            <Route path="/profile" element={<Profile />}>
              <Route path='/profile' element={<OnQueue/>}/>
              <Route path='/profile/completed' element={<Completed/>}/>
              <Route path='/profile/cancelled' element={<Cancelled/>}/>
            </Route>
            <Route path="/applyforajob" element={<ApplyForAJob/>} />
            <Route path="/applyforajob/:id" element={<ApplyForm/>} />
          </Route>
          <Route path='*' element={<h6>This page doesn't exist</h6>}/>
        </Routes>
      </Provider>
    </BrowserRouter>
    </>
  );
}

export default App;
