export function splitTimesByPeriod(times: string[]) {
    const periods: {
        daytime: string[],
        afternoon: string[],
        night: string[]
    } = {
        daytime: [],
        afternoon: [],
        night: []
    };

    times.forEach(timeSlot => {
        const [startTime] = timeSlot.split(" ");
        const [hour] = startTime.split(":").map(Number);

        if (hour >= 9 && hour < 12) {
            periods.daytime.push(timeSlot);
        } else if (hour >= 12 && hour < 17) {
            periods.afternoon.push(timeSlot);
        } else {
            periods.night.push(timeSlot);
        }
    });
    periods.daytime = formatTimes(periods.daytime)
    periods.afternoon = formatTimes(periods.afternoon)
    periods.night = formatTimes(periods.night)
    return periods;
}

export function formatTimes(times: string[]) {
    return times.map((time) => {
        const [hour, minute] = time.split(":").map(Number);
        const startPeriod = hour < 12 ? "AM" : "PM";
        const nextHour = (hour + 1) % 24;
        const endPeriod = nextHour < 12 ? "AM" : "PM";

        const formattedStart = `${hour % 12 === 0 ? 12 : hour % 12}:${minute.toString().padStart(2, "0")}${startPeriod}`;
        const formattedEnd = `${nextHour % 12 === 0 ? 12 : nextHour % 12}:${minute.toString().padStart(2, "0")}${endPeriod}`;

        return `${formattedStart} ${formattedEnd}`;
    });    
}    


export function get24HourTime(timeLapse: string) {
    const firstTime = timeLapse.split(' ')[0];    
    const [time, period] = [firstTime.slice(0, -2), firstTime.slice(-2)];
    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours !== 12)
        hours += 12;
    else if (period === 'AM' && hours === 12) 
        hours = 0;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}