import { WriterCardProps } from '../../../../models/blog'
import './writerCard.css'

function WriterCard(props: WriterCardProps) {
  const {name, image, role} = props
  return (
    <div className='writerCard'>
      <img src={image} alt='writer' className='writerImage'/>
      <div>
        <h4 className='mt-2'>{name}</h4>
        <p className='profession'>{role}</p>
      </div>
    </div>
  )
}

export default WriterCard