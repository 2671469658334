import React, { useEffect } from 'react'
import Login from '../../../components/auth/login/login/Login'
import Header from '../../../components/auth/header/Header'
import { useNavigate } from 'react-router-dom'
import '../authLayout.css'
import Verification from '../../../components/auth/verify/Verification'
import ResetPassword from '../../../components/auth/login/resetPassword/ResetPassword'
import AuthImage from '../../../components/auth/nestedComponents/authImage/AuthImage'
import { useAppDispatch, useAppSelector } from '../../../RTK/hooks/hooks'
import toast from 'react-hot-toast'
import { sendForgotPasswordRequest, verifyForgotPasswordRequest } from '../../../RTK/features/verifyForgotPasswordSlice/verifyForgotPasswordSlice'

function LoginLayout() {
  const [page, setPage] = React.useState<number>(1)
  const [email, setEmail] = React.useState('')
  const navigate = useNavigate()

  const {verifyError, loading, sendError} = useAppSelector((state) => state.verifyForgotPassword)
  const dispatch = useAppDispatch()
  useEffect(()=>{
    if(sendError)
      toast.error(sendError, {duration: 3000});
  },[sendError])

  async function requestOTP(){
    try{
      const result = await dispatch(sendForgotPasswordRequest(email)).unwrap()
      toast.success('Check your email for the code', {duration: 3000});
    } catch {}
  }
  async function handleAction(code:string) {
    try{
      const result = await dispatch(verifyForgotPasswordRequest({email, code})).unwrap()
      toast.success('Enter your new password', {duration: 3000})
      setPage(3);
    }
    catch{}
  }

  function diplayedPage(){  
    switch(page){
      case 1: return <Login setPage={setPage} setEmail={setEmail} email={email}/>;
      case 2: return <Verification setPage={setPage} email={email} requestOTP={requestOTP} handleAction={handleAction} error={verifyError} loading={loading}/>;
      case 3: return <ResetPassword setPage={setPage}/>;
      default: return null
    }
  }
  
  useEffect(() => {
    if (page == 0) {
      navigate('/', { replace: true })
    }
  }, [page, navigate])

  return (
    <div className='authLayout'>
      <Header setPage={setPage} show={page == 1} gray={page != 1}/>
      <div className={`authWra ${page!=1 && 'authWraBg'}`}>
        <div className={`${page == 1 && 'basis '}pageWra`}>
          {diplayedPage()}
        </div>
        <div className={`${page == 1 ? 'rightImage' : 'hidden'}`}>
          <AuthImage/>
        </div>
      </div>
    </div>
  )
}

export default LoginLayout