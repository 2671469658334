import { useEffect, useState } from 'react'
import Review from '../nestedComponents/review/Review'
import './rating.css'
import { OneRatingType } from '../../../models/homepage'
import { fetchData } from '../../../api/crudServices'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'


function Rating({isWideScreen}:{isWideScreen:boolean}) {
    const [ratings, setRatings] = useState<OneRatingType[]>([])
    const currentLnag = getLang(), {someRatings} = lang[currentLnag].home

    useEffect(()=>{
      async function getRatings() {
        const res = await fetchData('user/rating/getSix')
        setRatings(res.data)
      }
      getRatings()
    },[])
  return (
    <div className='rating paper'>
        <h3 className='blueBox'>{someRatings.label}</h3>
        <h2 className='header2'>{someRatings.title}</h2>
        <p className='secIntro'>{someRatings.smallDescription}</p>
        <div className="cardsWra reviews">
          {
            ratings.map((rating, index)=> {
              if(index > 2)
                return <Review key={rating.id} name={rating.user.name} image={rating.user.image} rating={rating.rating} feedback={rating.feedback}/>
              else if(isWideScreen)
                return <Review key={rating.id} name={rating.user.name} image={rating.user.image} rating={rating.rating} feedback={rating.feedback}/>
              else return 0
            })  
          }
        </div>
    </div>
  )
}

export default Rating