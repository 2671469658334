import { useEffect, useState } from 'react'
import ProviderCard from '../nestedComponents/providerCard/ProviderCard'
import './topProviders.css'
import { TopProvidersState } from '../../../models/homepage'
import { fetchData } from '../../../api/crudServices'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'

function TopProviders({isWideScreen}:{isWideScreen:boolean}) {
  const [providers, setProviders] = useState<TopProvidersState[]>([])
  const currentLang = getLang(), {topProviders} = lang[currentLang].home 
  useEffect(()=>{
      async function getProvider() {
        const res = await fetchData('user/doctor/getTopDoctors')
        setProviders(res.data)
      }
      getProvider()
  },[])

  return (
    <div className='topProviders paper'>
      <h3 className='blueBox'>{topProviders.label}</h3>
      <div className='centeredText'>
        <h2 className='header2'>{topProviders.title}</h2>
        <p className='secIntro'>
          {topProviders.smallDescription}
        </p>
      </div>
      <div className='cardsWra'>
        {providers.map((item, ind) => {
          if(ind < 3 || isWideScreen)
            return <ProviderCard key={item.id} item={item}/>
          else return 0
        }
        )}
      </div>
    </div>
  )
}

export default TopProviders


/*
export interface Root {
  id: number
  name: string
  email: string
  working_hours_start: string
  working_hours_end: string
  service: Service
  department: Department
  image: string
  average_rating: number
  rating_counts: RatingCounts
  all_ratings: AllRating[]
}

export interface Service {
  id: number
  name: string
}

export interface Department {
  id: number
  name: string
}

export interface RatingCounts {
  "1": number
  "2": number
  "3": number
  "4": number
  "5": number
}

export interface AllRating {
  id: number
  rating: string
  feedback: string
  user: User
  doctor: Doctor
}

export interface User {
  id: number
  name: string
  email: string
  image: string
}

export interface Doctor {
  name: string
}

*/