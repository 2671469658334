import { useEffect, useState } from 'react'
import JobCard from '../../components/applyForAJob/jobCard/JobCard'
import './applyForAJob.css'
import { ApplyForAJobState } from '../../models/applyForm'
import { fetchData } from '../../api/crudServices'
import { getLang } from '../../utils/functions/authCheck'
import lang from '../../utils/lang/lang'

function ApplyForAJob() {
  const [jobs, setJobs] = useState<ApplyForAJobState[]>([])
  const currentLang = getLang(), {applyForAJob} = lang[currentLang]

  useEffect(()=>{
    async function fetchJobs(){
      const res = await fetchData('user/job/index')
      setJobs(res.data)
    }
    fetchJobs()
  },[])

  return (
    <div className='applyForAJobWra'>
      <div className='applyForAJob paper'>
        <h3 className='blueBox'>{applyForAJob.label}</h3>
        <div className='centeredText'>
          <h2 className='header2'>{applyForAJob.title}</h2>
          <p className='secIntro'>{applyForAJob.smallDescription}</p>
        </div>    
        <div className='jobsWra'>
          {jobs.map((item)=><JobCard key={item.id} id={item.id} name={item.name} image={item.image} skills={item.skills} description={item.description} />)}
        </div>
      </div>
    </div>
  )
}

export default ApplyForAJob