import { useEffect, useState } from 'react'
import { WriterType } from '../../../models/blog'
import WriterCard from '../nestedComponents/writerCard/WriterCard'
import person from '../../../assets/pics/blog/person.png'

import './topWriters.css'
import { fetchData } from '../../../api/crudServices'
import lang from '../../../utils/lang/lang'
import { getLang } from '../../../utils/functions/authCheck'

function TopWriters() {
  const [writers, setWriters] = useState<WriterType>({})
  const currentLang = getLang(), {articles} = lang[currentLang]

  useEffect(()=>{
    async function fetchWriters() {
      const res = await fetchData('user/article/getTopPublishers')
      setWriters(res.data)
    }
    fetchWriters()
  } ,[])
  return (
    <div className='topWriters'>
      <h2 className='header2'>{articles.title3}</h2>
      <p className='secIntro'>{articles.smallDescription3}</p>
      <div className='writerCardWra'>
        {
          Object.entries(writers).map(([key, value]) => <WriterCard key={value.id} name={value.name} image={value.image} role={value.role}/>)
        }
      </div>
    </div>
  )
}

export default TopWriters