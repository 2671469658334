import { useParams } from 'react-router-dom'
import patient from '../../assets/pics/homepage/patient.webp'
import './bookAService.css'
import RatingWra from '../../components/bookAService/ratingWra/RatingWra'
import Booking from '../../components/bookAService/booking/Booking'
import { useEffect, useState } from 'react'
import { BookAServiceType } from '../../models/ourServices'
import { fetchData } from '../../api/crudServices'

function BookAService() {
    const {id} = useParams()
    const [service, setService] = useState<BookAServiceType>()

    useEffect(()=>{
        async function fetchService(){
            const res = await fetchData(`user/service/show/${id}`)
            setService(res.data)
        }
        fetchService()
    },[])

    if(!service)
        return <></>



    return (
    <div className='bookAService'>
        <img src={service.image} alt="pic" />       
        <div className="servicedets">
            <RatingWra rating={service.average_rating} ratings={service.all_ratings} ratingsCount={service.rating_counts}/>
            <Booking/>
        </div> 
    </div>
  )
}

export default BookAService