import { RatingBarProps } from "../../../../models/bookAService";
import './ratingBar.css'

function RatingBar(props: RatingBarProps ) {
  const { percentage, indicator } = props
    return (
      <div className='line'>
        <span>{indicator}</span>
        <div className='ratingBar'>
          <div
            style={{
              width: `${Math.min(Math.max(percentage, 0), 100)}%`,
            }}
          ></div>
        </div>
      </div>
    );
}

export default RatingBar