import { ReviewProps } from '../../../../models/homepage'
import './review.css'

function Review(props: ReviewProps) {
  const {name, image, rating, feedback} = props
  return (
    <div className='review'>
        <img src={image} alt='pic'/>
        <div>
            <h4>{name}</h4>
            <p className='profession'>{rating} stars</p>
            <p>{feedback}</p>
        </div>
    </div>
  )
}

export default Review