import { ChangeEvent, useEffect, useState } from 'react';
import BookingsCard from '../bookingsCard/BookingsCard'
import happy from '../../../../assets/pics/profile/happy.svg'
import neutral from '../../../../assets/pics/profile/neutral.svg'
import sad from '../../../../assets/pics/profile/sad.svg'
import './completed.css'
import SetStarRating from '../../../bookAService/nestedComponents/starRating/SetStarRating';
import { fetchData, postData } from '../../../../api/crudServices';
import toast from 'react-hot-toast';
import { YourAppointments } from '../../../../models/profile';
import { getLang } from '../../../../utils/functions/authCheck';
import lang from '../../../../utils/lang/lang';

function Completed() {
  const [openModalId, setOpenModalId] = useState(0);
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({rating: 3, feedback: ''});
  const [yourAppointments, setYourAppointments] = useState<YourAppointments[]>([])
  const currentLang = getLang(), {completed} = lang[currentLang].profile.appointments.navigation

  useEffect(()=>{
    async function fetchCompleted(){
      const res = await fetchData('user/appointment/getUserAppointments?status=completed')
      setYourAppointments(res.data)
    }
    fetchCompleted()
  },[])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>|{name:string, value: number}) => {
    const {name, value} = 'target' in e ? e.target : e;
    setData(pre => {return {...pre, [name]: value}});
  }
  const handleOpenModal = (id:number) => {
    setOpenModalId(id);
  };
  const handleCloseModal = () => {
    setData({feedback:'', rating: 3});
    setOpenModalId(0);
  };

  async function handleSubmit(id:number){
    try{
      setLoading(true)
      const res = await postData('user/rating/store', {...data, appointment_id: id})
      toast.success('Your rating has been recorded')
    }catch(error){
      console.error(error)
      toast.error(`An error occurred while sending your feedback`)
    }finally{
      setLoading(false)
    }
    handleCloseModal()
  }

  return (
    <div className='onQueue bookingsSec'>
      {yourAppointments.map((item) => {
        return (
          <BookingsCard key={item.id} appointment={item}>
            <button
              className="rateYourAppointment"
              onClick={() => handleOpenModal(item.id)}
            >
              {completed.rate}
            </button>
            {openModalId === item.id && (
              <div className="modalBackdrop" onClick={handleCloseModal}>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                  <span className='importantText'>{completed.rate}</span>
                  <img src={data.rating <= 2 ? sad : data.rating > 3 ? happy : neutral} alt="happy" />
                  <SetStarRating rating={data.rating} setRating={handleChange}/>
                  <span className='importantText'>{completed.comment}</span>
                  <textarea value={data.feedback} name='feedback' onChange={handleChange} placeholder={completed.placeholder}/>
                  <button disabled={!data.feedback || loading} onClick={()=>handleSubmit(item.id)}>{completed.submit}</button>
                </div>
              </div>
            )}
          </BookingsCard>
        );
      })}
    </div>
  )
}

export default Completed