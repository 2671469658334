import plus from '../../../../assets/pics/bookAService/plus.svg'
import { AddAServiceProps } from '../../../../models/bookAService'
import { getLang } from '../../../../utils/functions/authCheck'
import lang from '../../../../utils/lang/lang'
import './addAService.css'

function AddAService(props: AddAServiceProps) {
    const {setStep} = props, currentLang = getLang(), {bookings} = lang[currentLang]
    const arr = 0, styles = arr ? {bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderTop:'2px solid #D4D4D4', width:'100%', padding:'15px', backgroundColor:'white'} : {bottom: '50%', left: '50%', transform: 'translate(-50%, 50%)'}
  
    return (
    <div className='addAService hideScrollBar step'>
        <button onClick={()=>setStep(pre => pre+1)} className='addButton importantText' style={styles}>
            <img src={plus} alt="add"/>
            {!arr && <span>{bookings.addAService}</span>}
        </button>
    </div>

  )
}

export default AddAService