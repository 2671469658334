import CustomButton from '../nestedComponents/customButton/CustomButton';
import cake from '../../../assets/pics/auth/cake.svg'
import info from '../../../assets/pics/auth/info.svg'
import { ExtraInfoProps } from '../../../models/auth/auth';
import { useState } from 'react';
import './extraInfo.css'
import AuthGenderSelect from '../nestedComponents/authGenderSelect/AuthGenderSelect';
import NameInput from '../nestedComponents/nameInput/NameInput';
import { postData } from '../../../api/crudServices';
import toast from 'react-hot-toast';

function ExtraInfo(props: ExtraInfoProps) {
  const { setPage } = props;
  const [formData, setFormData] = useState({
    father_name:'',
    mother_name:'',
    birthdate: '',
    gender:'',
    phone:'',
  });
  function handleInputChange(e: React.ChangeEvent<HTMLInputElement> | { name: string; value: string; }) {
    const {name, value} = 'target' in e ? e.target : e
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  async function handleSubmit(){
    try{
      await postData('user/record/store', formData)
      toast.success('Your sign in is successful')
      setPage(pre => pre+1)
    }catch(err){
      console.error(err)
      toast.error(`An error has occurred ${err}`)
    }
  }

  return (
    <div className='page'>
      <h3 className='authH3'>Record information</h3>
      <div className='form space-y-16'>
        <div>
          <div className='textInputsWra'>
            <NameInput name={formData.father_name} palceHolder='Father Name' setName={(value)=>{setFormData(pre => {return {...pre, father_name: value}})}}/>
            <NameInput name={formData.mother_name} palceHolder='Mother Name' setName={(value)=>{setFormData(pre => {return {...pre, mother_name: value}})}}/>
            <div>
              <div className="input">
                <img src={cake} alt='cake' className='icon' />
                <input
                  type="date"
                  name='birthdate'
                  id='birthdate'
                  placeholder="Date of birth"
                  value={formData.birthdate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <AuthGenderSelect formData={formData} handleChange={handleInputChange}/>
            <div>
              <div className="input">
                <img src={info} alt='cake' className='icon' />
                <input
                  type="number"
                  name='phone'
                  id='phone'
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomButton
          content='Sing up'
          disabled={!(formData.gender && formData.birthdate && formData.phone && formData.father_name && formData.mother_name)}
          action={() =>handleSubmit()}
        />
      </div>
    </div>
  );
}

export default ExtraInfo;