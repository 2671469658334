import { useEffect, useState } from 'react'
import day from '../../../../assets/pics/bookAService/day.svg'
import afternoon from '../../../../assets/pics/bookAService/afternoon.svg'
import night from '../../../../assets/pics/bookAService/night.svg'
import './chooseDate.css'
import { AvailableDates, ChooseDateProps, Hours } from '../../../../models/bookAService'
import toast from 'react-hot-toast'
import { fetchData } from '../../../../api/crudServices'
import { get24HourTime, splitTimesByPeriod } from '../../../../utils/functions/categorizeTimes'
import lang from '../../../../utils/lang/lang'
import { getLang } from '../../../../utils/functions/authCheck'

export default function ChooseDate(props: ChooseDateProps) {
  const {setStep, setAppointment, appointment} = props
  const [availableDates, setAvailableDates] = useState<AvailableDates>({})
  const [hours, setHours] = useState<Hours>({}as Hours)
  const currentLang = getLang(), {bookings} = lang[currentLang]

  useEffect(()=>{
    async function fetchDates() {
      const res = await fetchData(`user/doctor/getAvailableHoursForWeek/${appointment.doctor}`)
      setAvailableDates(res.data)
    }
    fetchDates()
  },[])

  useEffect(()=>{
    if(availableDates[appointment.day]){
      const transformHours = splitTimesByPeriod(availableDates[appointment.day])
      setHours(transformHours)
    }
  },[appointment.day])

  return (
    <div className="chooseDate hideScrollBar step">
      <h4 className="importantText">{bookings.chooseDate}</h4>

      <div className="dateSelector hideScrollBar">
        <div className="datesWra hideScrollBar">  
          {Object.keys(availableDates).map((key, i) => (
            <button
              key={key}
              className={`${appointment.day === key ? 'text-dodger-blue border-dodger-blue' : 'text-[#737377]'} dateSelectButton`}
              onClick={(e) =>setAppointment({name:'day',value:key})}>
              <span>{key}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="timeSlots">
        {hours && Object.entries(hours).map(([key, times]: [key:string, times:string[]]) => (
          <div key={key} className="timeSlot">
            <div className="label">
              {key == 'daytime' ? <img src={day} className="w-4 h-4"/> : key == 'afternoon' ? <img src={afternoon} className="w-4 h-4"/> : <img src={night} className="w-4 h-4"/>}
              <span>{currentLang == 'en' ? key : key == 'daytime' ? "صباحا" : key == 'afternoon' ? "ظهرا" : 'مساء'}</span>
            </div>
            <div className="timesGrid">
              {times.map((time) => {
                const time24 = get24HourTime(time);
                return (
                <button
                  key={time}
                  onClick={(e) =>setAppointment({name:'time',value:time24})}
                  className={`timeSelectButton
                    ${appointment.time === time24
                      ? 'bg-dodger-blue text-white'
                      : ' text-blue-500'
                    }`
                  }
                >
                  {time}
                </button>
              )})}
            </div>
          </div>
        ))}
      </div>

      <button 
        className="confirmButton"
        onClick={() => {
          if(!appointment.day)
            toast.error('Choose a day', {duration: 3000})
          else if(!appointment.time)
            toast.error('Choose a suitable time', {duration: 3000})
          else 
            setStep(pre => pre+1)
        }}
      >
        {bookings.next}
      </button>
    </div>
  )
}