import ImportantBlog from '../nestedComponents/importantBlog/ImportantBlog'
import './mostImportantBlogs.css'
import { Link } from 'react-router-dom'
import { ImportantArticle } from '../../../models/blog'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'


function MostImportantBlogs(props: {data: ImportantArticle[]}) {
  const {data} = props
  const currentLang = getLang(), {articles} = lang[currentLang]

  if(!data)
    return <></>

  return (
    <div className='mostImportantBlogs'>
      <h2 className='header2'>{articles.title1}</h2>
      <p className='secIntro'>{articles.smallDescription1}</p>
      <div className='importantBlogs w-full'>
        <div className='bigImportantBlog'>
          <img src={data[0].image} alt='img' className='bigPic'/>
          <div className='bigDes'>
            <span className='secondaryTitle marginY inline-block'>{data[0].title}</span>
            <p>{data[0].description}</p>
            <p className='marginY'>{`${data[0].date_publish} By ${data[0].publisher.name}`}</p>
            <Link to={`/blog/${data[0].id}`}>{articles.button}</Link>
          </div>
        </div>
        {
          data.map((blog, i)=>{
            if(!i)
              return <></>
            return <ImportantBlog key={blog.id} creation={`${data[0].date_publish} By ${data[0].publisher.name}`} buttonContent={articles.button} to={`/blog/${blog.id}`} image={blog.image} title={blog.title} des={blog.description}/>
          })
        }
      </div>
    </div>
  )
}

export default MostImportantBlogs