import React from 'react'
import './customButton.css'

function CustomButton({disabled = false, content, action}:{disabled?:boolean, content:string, action:()=>void}) {
  return (
    <button type="button" className='customButton' disabled={disabled} onClick={action}>
        {content}
    </button>
  )
}

export default CustomButton