import menu from '../../../assets/pics/appBar/menu.svg'
import Search from '../../appBar/search/Search'
import Logo from '../logo/Logo'
import Drawer from '../../appBar/drawer/Drawer'
import { useToggle } from '../../../utils/hooks/toggle'

function AppBarMo() {
  const [open, toggle] = useToggle()

  return (
    <nav className='appBarMo'>
        <Drawer open={open} toggle={toggle}/>
        <div className='upperMoPart'>
            <Logo color='white'/>
            <button>
                <img src={menu} alt='menu' onClick={toggle}/>
            </button>
        </div>
        <div>
          <Search mobile/>
        </div>
    </nav>
  )
}

export default AppBarMo