import ServiceCard from '../../components/sharedComponents/serviceCard/ServiceCard'
import TopWriters from '../../components/blog/topWriters/TopWriters'
import MostImportantBlogs from '../../components/blog/mostImportantBlogs/MostImportantBlogs'
import './blog.css'
import { useEffect, useState } from 'react'
import { BlogType } from '../../models/blog'
import { fetchData } from '../../api/crudServices'
import { getLang } from '../../utils/functions/authCheck'
import lang from '../../utils/lang/lang'

function Blog() {
    const [blogs, setBlogs] = useState<BlogType>({} as BlogType)
    const currentLang = getLang(), {articles} = lang[currentLang]
    useEffect(()=>{
      async function fetchBlogs() {
        const res = await fetchData('user/article/getImportantWithNewest')
        setBlogs(res.data)
      }
      fetchBlogs()
    },[])

    return (
    <div className='blog' id='blog'>
        <MostImportantBlogs data={blogs.important_articles}/>
        <div className='latestNews'>
            <h2 className='header2'>{articles.title2}</h2>
            <p className='secIntro'>{articles.smallDescription2}</p>
            <div className='cardsWra text-start'>
                {
                  blogs?.latest_articles?.map((blog)=><ServiceCard key={blog.id} buttonContent={articles.button} to={`/blog/${blog.id}`} image={blog.image} des={blog.description} title={blog.title}/>)
                }
            </div>
        </div>
        <TopWriters/>
    </div>
  )
}

export default Blog