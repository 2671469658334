import { Link } from 'react-router-dom'
import { JobCardProps } from '../../../models/applyForm'
import './jobCard.css'

function JobCard(props: JobCardProps) {
  return (
    <div className='jobCard'>
        <Link to={`./${props.id}`}>
            <img src={props.image} alt='doctor'/>
        </Link>
        <div className='jobCardDes'>
          <span>{props.name}</span>
          <p>{props.description}</p>
            <div className='jobSerWra'>
              {props.skills.map((skill, i)=><p key={i} className='jobSer'>{skill}</p>)}
            </div>  
        </div>
    </div>
  )
}

export default JobCard