import { fetchData } from "../../api/crudServices";

export async function fetchCities(){
  try{
    const res = await fetchData('user/cities')
    return res.data
  }
  catch(error){
    return []
  }
}