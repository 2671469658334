import React from 'react'
import './rememberMe.css'

function RemeberMe({handleChange, remember_me}:{handleChange:(e:React.ChangeEvent<HTMLInputElement>
)=>void, remember_me:'true'|'false'}) {
  return (
    <div className='rememberMe'>
        <input type="checkbox" value={remember_me} onChange={(e)=>handleChange(e)} name="remember-me" id="remember-me" />
        <p>Remeber me</p>
    </div>
  )
}

export default RemeberMe