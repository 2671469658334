import { Link } from 'react-router-dom'
import { ServiceCardProps } from '../../../models/ourServices'
import save from '../../../assets/pics/save.svg'
import './serviceCard.css'

function ServiceCard(props: ServiceCardProps) {
  const {buttonContent, image, to, title = 'Lorem ipsum dolor sit amet consectur. ', des = 'Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec.'} = props;
  
  return (
    <div className='serviceCard'>
        <div className='serviceImWra'>
          {/*<button className='saveButton'>
            <img src={save} alt='save' className='save'/>
          </button>*/}
          <img src={image} alt='pic' className='serviceImage'/>
          <Link to={to} className='whiteLink'>{buttonContent}</Link> 
        </div>
        <div>
          <span className="secondaryTitle">{title}</span>
          <p className='mt-3'>{des}</p>
        </div>
    </div>
  )
}

export default ServiceCard