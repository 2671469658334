import React from 'react'
import location from '../../../assets/pics/appBar/location.svg'
import downArrow from '../../../assets/pics/appBar/downArrow.svg'
import { getLang } from '../../../utils/functions/authCheck'

function Location() {
  return (
    <div className='miniFlex'>
        <img src={location} alt="ic"/>
        {getLang() == 'en' ?'Damascus, Syria' : 'دمشق، سورية'}
        <button>
          <img src={downArrow} alt="ic"/>
        </button>
    </div>
  )
}

export default Location