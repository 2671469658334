import { useParams } from 'react-router-dom';
import { ChooseADoctorProps, DoctorType } from '../../../../models/bookAService'
import AppointmentCard from '../appointmentCard/AppointmentCard'
import './chooseADoctor.css'
import { useEffect, useState } from 'react';
import { fetchData } from '../../../../api/crudServices';
import { getLang } from '../../../../utils/functions/authCheck';
import lang from '../../../../utils/lang/lang';

function ChooseADoctor(props: ChooseADoctorProps) {
  const [doctors, setDoctors] = useState<DoctorType[]>([])
  const {setStep, setAppointment} = props;
  const {id} = useParams(), currentLang = getLang(), {bookings} = lang[currentLang]


  function handleClick(id:string){
    setAppointment(pre=>{return {...pre, doctor:id}})
    setStep(pre => pre+1);
  }

  useEffect(()=>{
    async function fetchDoctors(){
      const res = await fetchData(`user/service/get/${id}/doctors`)
      setDoctors(res.data)
    }
    fetchDoctors()
  },[])
  
  return (
    <div className='chooseADoctor hideScrollBar step'>
      <h4 className='importantText'>{bookings.chooseADoctor}</h4>
      {doctors.length ? doctors.map((doc)=>{
        const DId = String(doc.id);
        return <button key={doc.id} onClick={()=>handleClick(DId)}>
          <AppointmentCard name={doc.name} email={doc.email} image={doc.image} hideDateBar/>
        </button>
      }) : currentLang == 'en' ? 'No Doctors available': 'لايوجد دكاترة متاحين'}

    </div>
  )
}

export default ChooseADoctor