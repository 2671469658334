import { ChangeEvent, useEffect, useState } from 'react';
import BookingsCard from '../bookingsCard/BookingsCard'
import './onQueue.css'
import ChooseDate from '../../../bookAService/nestedComponents/chooseDate/ChooseDate';
import CheckInfo from '../../../bookAService/nestedComponents/checkInfo/CheckInfo';
import { YourAppointments } from '../../../../models/profile';
import { fetchData } from '../../../../api/crudServices';
import { getLang } from '../../../../utils/functions/authCheck';
import lang from '../../../../utils/lang/lang';

function OnQueue() {
  const [openModalId, setOpenModalId] = useState(0);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ day:'', time:'', doctor:''});
  const [yourAppointments, setYourAppointments] = useState<YourAppointments[]>([])
  const currentLang = getLang(), {profile} = lang[currentLang]

  useEffect(()=>{
    async function fetchOnQueue(){
      const res = await fetchData('user/appointment/getUserAppointments?status=on queue')
      setYourAppointments(res.data)
    }
    fetchOnQueue()
  },[])

  const handleOpenModal = (id:number, DId: number) => {
    setData(pre=>{return ({...pre, doctor: String(DId)})})
    setOpenModalId(id);
  };
  const handleCloseModal = () => {
    setData({ day:'', time:'', doctor:''});
    setStep(0)
    setOpenModalId(0);
  };
  const displayedComponent = ()=> {
    switch(step){
      case 0: return <ChooseDate setStep={setStep} appointment={data} setAppointment={(e:{name:string, value:string})=>{
        setData(pre=>{return {...pre, [e.name]:e.value}})
      }}/>
      case 1: return <CheckInfo update={String(openModalId)} appointment={data} handleEnd={handleCloseModal} setStep={setStep}/>;
      default: handleCloseModal()
   }
  }
  return (
    <div className='completed bookingsSec'>
      {yourAppointments.map((item) => {
        return (

          <BookingsCard key={item.id} appointment={item}>
            <div className='completedCheckInfoButtons'>
              <button className='cancel'>{profile.appointments.buttons[1]}</button>        
              <button className='changeDate'
                onClick={() => handleOpenModal(item.id, item.doctor.id)}
              >{profile.appointments.buttons[0]}</button>        
            </div>
            {openModalId === item.id && (
              <div className="modalBackdrop" onClick={handleCloseModal}>
                <div className="modal" onClick={(e)=>e.stopPropagation()}>
                  {displayedComponent()}
                </div>
              </div>
            )}
          </BookingsCard>
        )
      })}
    </div>
  )
}

export default OnQueue