import { Link } from 'react-router-dom'
import save from '../../../../assets/pics/save.svg'
import { ImportantBlogProps } from '../../../../models/blog'
import './importantBlog.css'
import food from '../../../../assets/pics/homepage/food.webp'

function ImportantBlog(props: ImportantBlogProps) {
  const {buttonContent, image, to, title, des, creation} = props

  return (
    <div className='importantBlog'>
        <div className='importantBlogPicSec'>
          {/*<button className="saveButton">
            <img src={save} alt='save' className='save'/>
          </button>*/}
          <img src={image} alt='pic' className='importantBlogPic'/>
          <Link to={to}>{buttonContent}</Link> 
        </div>
        <div className="importantBlogDes">
          <span className='secondaryTitle'>{title}</span>
          <p>{des}</p>
          <p className='marginY'>{creation}</p>
        </div>
    </div>
  )
}

export default ImportantBlog