import { BookingsCardProps } from '../../../../models/bookAService'
import avatar from '../../../../assets/pics/homepage/food.webp'
import calendar from '../../../../assets/pics/calendar.svg'
import clock from '../../../../assets/pics/bookAService/clock.svg'
import './bookingsCard.css'
import { formatTimes } from '../../../../utils/functions/categorizeTimes'

function BookingsCard(props:BookingsCardProps) {
    const {children, appointment} = props
  return (
    <div className='bookingsCard'>
      <div className='bookingsFlexWra'>
        <img src={avatar} alt='doctor'/>
        <div>
          <span>{appointment.doctor.name}</span>
          <p>{appointment.doctor.email}</p>
          <div className="bookingsDateInfo">
            <div>
              <img src={calendar} alt='icon'/>
              <span>{appointment.date}</span>
            </div>                  
            <div>
              <img src={clock} alt='icon'/>
              <span>{formatTimes([appointment.time])[0]}</span>
            </div>  
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default BookingsCard