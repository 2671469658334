import ServiceCard from '../../sharedComponents/serviceCard/ServiceCard'
import food from '../../../assets/pics/homepage/food.webp'
import './blog.css'
import { useEffect, useState } from 'react'
import { ServiceCardType } from '../../../models/homepage'
import { fetchData } from '../../../api/crudServices'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'

function Blog() {
    const [blogs, setBlogs] = useState<ServiceCardType[]>([])
    const currentLnag = getLang(), {ourBlogs} = lang[currentLnag].home
    
    useEffect(()=>{
      async function fetchBlogs() {
        const res = await fetchData('user/article/getThree')
        setBlogs(res.data)
      }
      fetchBlogs()
    },[])
  return (
    <div className='homeBlog'>
        <h3 className='blueBox'>{ourBlogs.label}</h3>
        <div className='centeredText'>
            <h2 className='header2'>{ourBlogs.title}</h2>
            <p className='secIntro'>{ourBlogs.smallDescription}</p>
        </div>
        <div className='cardsWra'>
          {
            blogs.map((blog, i)=><ServiceCard key={blog.id} to={`/blog/${blog.id}`} buttonContent={ourBlogs.button} image={i === 1 ? food : blog.image} des={blog.description} title={blog.name}/>)
          }
        </div>
    </div>
  )
}

export default Blog