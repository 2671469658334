import earth from '../../../assets/pics/appBar/earth.svg';
import Select, { StylesConfig } from 'react-select';
import { getLang } from '../../../utils/functions/authCheck';

function LanguageToggle(props: any) {
  const dodgerBlue = '#5CA2FB', currentLang = getLang();
  const selectOptions = [
    { value: 'en', label: currentLang ==='en' ? 'English': 'الانجلزية' },
    { value: 'ar', label: currentLang ==='en' ? 'Arabic': 'العربية' },
  ];

  const customStyles: StylesConfig<{ label: string; value: string }> = {
    control: (provided) => ({
      ...provided,
      color:'white',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      border: 'none',
      borderRadius: '8px',
      outline: 'black',
      boxShadow: 'none',
      height: '46px',
      paddingLeft: `${getLang() === 'en' ? '28px' : 'auto'}`,
      paddingRight: `${getLang() === 'ar' ? '28px' : 'auto'}`,
      fontSize: '16px',
    }),
    singleValue:(provided) => ({
      ...provided,
      color:'white'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'white',
      marginTop: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? dodgerBlue : 'transparent',
      color: state.isSelected || state.isFocused ? 'white' : '#16495f',
      padding: '10px 15px',
      fontSize: '16px',
      ':active': {
        backgroundColor: dodgerBlue,
        color: 'white',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
      cursor: 'pointer',
      paddingRight: '4px',
      paddingLeft:'4px',
      ":hover":{
        color:'white'
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '150px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  };

  return (
    <div className="miniFlex">
      <img src={earth} alt="Earth Icon" className='absolute'/>
      <Select
        options={selectOptions}
        styles={customStyles}
        isSearchable={true}
        placeholder="Select Language"
        defaultValue={selectOptions.find((option) => option.value === (localStorage.getItem('lang') === 'ar' ? 'ar' : 'en'))}
        onChange={(selected)=>{
          const a = selected as {value:string , label:string} ;
          localStorage.setItem('lang', a.value)
          window.location.reload();
        }}
      />
    </div>
  );
}

export default LanguageToggle;
