const lang = {
    en: {
      navigation:['Homepage', 'Blog','Apply for a job'],
      home: {
        companyIntro: {
          button: "Our Deparments",
          text1: "With us",
          text2: "you are safe",
          text3: "so don't worry"
        },
        ourVision: {
          title: "Your health is safe in our hands",
          smallDescription: "Driving innovation for a better future.",
          label: "Our Vision",
          sec1: {
            title: 'Lorem ipsum dolor sit amet consectur.', 
            des: 'Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec. Nibh a nullam sollicitudin et ac lacus. Volutpat nibh laoreet fames scelerisque elementum mauris ornare in neque'
          },
          sec2: {
            title: 'Lorem ipsum dolor sit amet consectur.', 
            des: 'Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec. Nibh a nullam sollicitudin et ac lacus. Volutpat nibh laoreet fames scelerisque elementum mauris ornare in neque'
          },
          button: 'Our Departments'
        },
        ourDepartments: {
          title: "Your life our responsibility",
          smallDescription: "Lorem ipsum dolor sit amet consectur.",
          label: "Our Departments",
          button: "Check it out",
        },        
        topProviders:{
          title: "Some of our best providers",
          smallDescription: "Lorem ipsum dolor sit amet consectur.",
          label: "Top Providers",
        },
        ourBlogs: {
          title: "Latest health and medical news",
          smallDescription: "Lorem ipsum dolor sit amet consectur.",
          label: "Blogs",
          button: "Read more",
        },
        someRatings: {
          title: "Some of our customers' reviews",
          smallDescription: "Lorem ipsum dolor sit amet consectur.",
          label: "Ratings",
        },
      },
      applyForAJob: {
        title: "select the section you want to work in",
        smallDescription: "Lorem ipsum dolor sit amet consectur.",
        label: "Apply for a job",
      },
      jobForm: {
        label: "Application Form",
        description:['Department', 'Location', 'Job duties', 'Education', 'Knowledge, skills and abilities', 'Experience'],
        form:['Name', 'Phone', 'Email', 'Gender', 'Description', 'Your CV'],
        fileInput:'Click or drag the file here to exoprt it' ,
        button: 'Submit',
      },
      articles: {
        title1: "Most important blogs",
        smallDescription1: "Lorem ipsum dolor sit amet consectur.",
        title2: "Latest news",
        smallDescription2: "Lorem ipsum dolor sit amet consectur.",
        title3: "Top Writers",
        smallDescription3: "Lorem ipsum dolor sit amet consectur.",
        button: 'Read More'
      },
      ourServices: {
        title: 'Services of the section',
        smallDescription: 'Lorem ipsum dolor sit amet consectur.',
        button: 'Check it out',
        info:['Appointments', 'Professional doctors', 'stars', 'Not rated yet']
      },
      bookings:{
        addAService: 'Book a service', 
        chooseADoctor: 'Choose your doctor',
        chooseDate: 'Choose date and time',
        checkInfo: 'Date and time',
        pay: 'Choose your payment method',
        cancel: 'Cancel',
        confirm: 'Confirm',
        next: 'Next',
      },
      profile: {
        personCard:['Blood type:', 'Last medical check:'] ,
        appointments:{
          navigation:{
            title: 'See your bookings here',
            smallDescription:'Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.',
            label: 'Your bookings',
            navigation: ['On queue','Completed','Cancelled'],
            completed:{
              submit: 'Submit',
              rate: 'Rate your appointment',
              comment: 'Leave a comment',
              placeholder: 'Your comment..'
            },
          },
          buttons: ['Change Date', 'Cancel', 'Rate your appointment']
        },
        form: {
          title: 'Got a complaint?',
          coloredTitle: 'Tell Us',
          formPlaceholders:['Full name', 'Your email', 'Phone number', 'Write your complaint'],
          button: 'Send your complaint'
        },
      },
    },
    ar: {
      navigation:['الرئيسية', 'المقالات','التقديم على وظيفة'],
      home: {
        companyIntro: {
          button: "أقسامنا",
          text1: "كن معنا",
          text2: "تكون في أمان",
          text3: "لذا لا تقلق"
        },
        ourVision: {
          title: "صحتك في امان عندما تكون بايدينا",
          smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
          label: "رؤيتنا",
          sec1: {
            title: 'لوريم إيبسوم دولور سيت أميت كونسيكتيتور.', 
            des: 'لوريم إيبسوم دولور سيت أميت كونسيكتيتور. فيتاي فيليت بوروس ديجنيزيم أت كوام فولبوتات. أك ساجيتيس كورسوس رونكوس أوت أورنا أورسي. سيت أك بيلينتيسك ناسيتر تيمبور أورنا نيك. نيب أ نولام سوليسيتودين إت أك لاكوس. فولبوت نيب لوريت فاميس سكيليريسك إليمنتوم موريس أورناري إن نيكي.'
          },
          sec2: {
            title: 'لوريم إيبسوم دولور سيت أميت كونسيكتيتور.', 
            des: 'لوريم إيبسوم دولور سيت أميت كونسيكتيتور. فيتاي فيليت بوروس ديجنيزيم أت كوام فولبوتات. أك ساجيتيس كورسوس رونكوس أوت أورنا أورسي. سيت أك بيلينتيسك ناسيتر تيمبور أورنا نيك. نيب أ نولام سوليسيتودين إت أك لاكوس. فولبوت نيب لوريت فاميس سكيليريسك إليمنتوم موريس أورناري إن نيكي.'
          },
          button: 'أقسامنا'
        },
        ourDepartments: {
          title: "حايتك هي مسؤوليتنا",
          smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
          label: "أقسامنا",
          button: "تفقد الخدمة",
        },
        topProviders:{
          title: "بعض أفضل مزودي الخدمات",
          smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
          label: "أفضل المزودين",
        },
        ourBlogs: {
          title: "اخر اخبار الطب والصحة",
          smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
          label: "الماقالات",
          button: "اقرأ المزيد",
        },
        someRatings: {
          title: "بعض التقييمات من عملائنا",
          smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
          label: "تقييمات",
        },
      },
      applyForAJob: {
        title: "اختر القسم الذي تود العمل به",
        smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
        label: "طلب وظيفة",
      },
      jobForm: {
        label: "نموذج التقديم",
        description:['القسم', 'الموقع', 'مهام العمل', 'التعليم', 'الثقافة والخبرات والقدرات', 'الخبرة'],
        form:['الاسم', 'رقم الهاتف', 'البريد الالكتروني', 'الجنس', 'الوصف', 'السيرة الذاتية'],
        fileInput: 'أنقر أو ضع الملف هنا لتصديره',
        button: 'إرسال',
      },
      articles: {
        title1: "أهم المقالات",
        smallDescription1: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
        title2: "اخر الأخبار",
        smallDescription2: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
        title3: "أفضل الكاتبين",
        smallDescription3: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
        button:'اقرأ المزيد'
      },      
      ourServices: {
        title: 'خدمات القسم',
        smallDescription: "لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
        button: 'تفقد الخدمة',
        info:['موعد', 'دكاترة محترفين', 'نجمة', 'لم يقيم بعد']
      },
      bookings:{
        addAService: 'احجز خدمة', 
        chooseADoctor: 'اختر طبيبا',
        chooseDate: 'اختر التاريخ والوقت',
        checkInfo: 'التاريح والوقت',
        pay: 'اختر طريقة الدفع',
        cancel: 'الغاء',
        confirm: 'تأكيد',
        next: 'التالي',
      },
      profile: {
        personCard:['زمرة الدم:', 'آخر فحص طبي:'] ,
        appointments:{
          navigation:{
            title: 'تفقد حجوزاتك هنا',
            smallDescription:"لوريم إيبسوم دولور سيت أميت كونسيكتيتور.",
            label: 'ججوزاتك',
            navigation: ['منتظر','منتهي','ملغي'],
            completed:{
              submit: 'إرسال',
              rate: 'قيم موعدك',
              comment: 'اترك تعليقا',
              placeholder: 'تعليقك..'
            },
          },
          buttons: ['تغيير الموعد', 'إلغاء', 'قيم موعدك']
        },
        form: {
          title: 'لديك شكوى؟',
          coloredTitle: 'أخبرنا بها',
          formPlaceholders:['الاسم الكامل', 'البريد الاكتروني', 'رقم الهاتف', 'اكتب شكواك هنا'],
          button: 'ارسل الشكوى'
        },
      },
    },     
  };
  
  export default lang;
  