import { NavLink } from 'react-router-dom'
import './bookings.css'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'

function Bookings() {
  const currentLang = getLang(), {profile} = lang[currentLang]

  return (
    <>
        <h3 className='blueBox'>{profile.appointments.navigation.label}</h3>
        <div className='centeredText'>
            <h2 className='header2'>{profile.appointments.navigation.title}</h2>
            <p className='secIntro'>{profile.appointments.navigation.smallDescription}</p>
        </div>
        <div className='bookingsLinks'>
            <NavLink className={({ isActive }) => (isActive ? 'activeBookingSec': '')} to='.' end>{profile.appointments.navigation.navigation[0]}</NavLink>
            <NavLink className={({ isActive }) => (isActive ? 'activeBookingSec': '')+' firstLink'} to='./completed'>{profile.appointments.navigation.navigation[1]}</NavLink>
            <NavLink className={({ isActive }) => (isActive ? 'activeBookingSec': '')} to='./cancelled'>{profile.appointments.navigation.navigation[2]}</NavLink>
        </div>
    </>
  )
}

export default Bookings