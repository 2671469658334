import { useMemo } from 'react'
import { RatingWraProps } from '../../../models/ourServices'
import RatingBar from '../nestedComponents/ratingBar/RatingBar'
import RatingCard from '../nestedComponents/ratingCard/RatingCard'
import StarRating from '../nestedComponents/starRating/StarRating'
import './ratingWra.css'
import { getLang } from '../../../utils/functions/authCheck'

function RatingWra(props: RatingWraProps) {
  const {rating, ratings, ratingsCount} = props
  const total = useMemo(()=> Object.values(ratingsCount).reduce((sum, value) => sum + value, 0), [ratingsCount])
  return (
    <div className='ratingWra hideScrollBar'>
      <div className="ratingWraOverview">
        <div className='generalOverview'>
          <h2 className='header2'>{rating}</h2>
          <StarRating rating={rating} h='25' w='25'/>
          <p>({total} {getLang() == 'en' ? 'reviews' : 'مراجعة'})</p>
        </div>
        <div className="verticalSeperator"></div>
        <div className='detailedOverview'>
          <RatingBar percentage={(ratingsCount[5]*100)/total} indicator='5'/>
          <RatingBar percentage={(ratingsCount[4]*100)/total} indicator='4'/>
          <RatingBar percentage={(ratingsCount[3]*100)/total} indicator='3'/>
          <RatingBar percentage={(ratingsCount[2]*100)/total} indicator='2'/>
          <RatingBar percentage={(ratingsCount[1]*100)/total} indicator='1'/>
        </div>
      </div>
      {
        ratings.map((rating)=>
          <div key={rating.id}>
            <div className='seperate'></div>
            <RatingCard user={rating.user} rating={rating.rating} feedback={rating.feedback} doctor={rating.doctor}/>
          </div>
        )
      }

    </div>
  )
}

export default RatingWra