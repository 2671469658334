import { StylesConfig } from 'react-select';
import person from '../../../../assets/pics/auth/person.svg'
import { GenderSelectProps } from '../../../../models/applyForm';
import Select from 'react-select';
import { CustomizableSelectOptionType } from '../../../../models/auth/auth';


function AuthGenderSelect(props: GenderSelectProps) {
  const {formData, handleChange } = props;
  const dodgerBlue = '#5CA2FB', softGray = '#F7F7F7', lightGray = '#F2F2F2', darkGray = '#404040';

  const options = [{label:'Male',value:'0'},{label:'Female',value:'1'}]

  const customStyles: StylesConfig<CustomizableSelectOptionType> = {
    control: (provided) => ({
      ...provided,
      backgroundColor: softGray,
      borderColor: 'transparent',
      border: '2px solid transparent',
      borderRadius: '8px',
      outline: 'black',
      boxShadow: 'none',
      height: '46px',
      width: '100%',
      paddingLeft: '29px',
      fontSize: '16px',
      ':hover':{
        border: '2px solid transparent',
      },
      ':focus-within':{
        border: '2px solid black'
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: lightGray,
      marginTop: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? dodgerBlue : 'transparent',
      color: state.isSelected || state.isFocused ? 'white' : '#16495f',
      padding: '10px 15px',
      fontSize: '16px',
      ':active': {
        backgroundColor: `${dodgerBlue}`,
        color:'white'
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: darkGray,
      cursor: 'pointer',
      paddingRight: '4px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '150px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  };


  const handleSelectChange = (selectedOption: {label: string; value: string;}) => {
    if (selectedOption) {
      handleChange({ name:'gender' , value: selectedOption.value });
    }
  };

  return (
    <div>
      <div className='input'>
        <Select
          options={options}
          placeholder="Gender"
          name='gender'
          id='gender'
          styles={customStyles}
          isSearchable={true}
          value={options.find(option => option.value === formData['gender']) || null}
          onChange={(selected)=>handleSelectChange(selected as {label: string; value: string;})}
        />
        <img src={person} alt='info' className='icon'/>
      </div>
    </div>
  );
}

export default AuthGenderSelect