import { useParams } from 'react-router-dom';
import like from '../../assets/pics/like.svg'
import './readMore.css';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import Comment from './nestedComponents/comment/Comment';
import { fetchData, postData } from '../../api/crudServices';
import { DetailedBlogType } from '../../models/blog';
import { authCheck, getLang } from '../../utils/functions/authCheck';
import { useAppSelector } from '../../RTK/hooks/hooks';

function ReadMore() {
  const { id } = useParams(), currentLang = getLang();
  const [text, setText] = useState('')
  const [blog, setBlog] = useState<DetailedBlogType>()
  const [liked, setLiked] = useState(false)
  const [sending, setSending] = useState(false)
  const user = useAppSelector((state) => state.user)

  useEffect(()=>{
    async function fetchBlog(){
      const res = await fetchData(`user/article/get_one/${id}`)
      setBlog(res.data)
    }
    fetchBlog()
  },[])
  function handleInput(e: ChangeEvent<HTMLTextAreaElement>){
    if(sending)
      return
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setText(textarea.value);
  }

  async function handleKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (text.trim() === '') return;
      if(!sending){
        setSending(true)
        try{
          await postData('user/comment/store', { article_id: id, comment: text });
                setText('');
          const res = await fetchData(`user/article/get_one/${id}`);
          setBlog(res.data);
        }finally{
          setSending(false)
        }
      }
    }
  }

  if(!blog)
    return <></>

  return (
    <div className='readMore'>
      <div className="readMorePaper">
        <h2 className='header2'>{blog.title}</h2>
        <p className='secIntro'>{`${blog.date_publish} By ${blog.publisher.name}`}</p>
        <div
          dangerouslySetInnerHTML={{ __html: blog.content }}
          className="article-content"
        ></div>
        <div className='buttons'>
          <button className='reactButton' onClick={()=>setLiked(!liked)}>
            { authCheck() && <svg width="28" height="28" viewBox="0 0 40 40" fill={`${liked ? '#0B74FA' : 'none'}`} xmlns="http://www.w3.org/2000/svg"
              onClick={async ()=>{await postData('/user/like/toggle', {likable_id: id, likable_type: 'article'})}}
            >
              <path d="M11.6668 34.1667H5.00016C4.07968 34.1667 3.3335 33.4203 3.3335 32.5V19.1667C3.3335 18.2462 4.0797 17.5 5.00016 17.5H11.6668" stroke={`${liked ? '#0B74FA' : "#737377"}`} stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M29.9127 34.1669H11.6665V17.5002L23.1928 5.8335L23.311 5.95316C25.7198 8.39133 26.3963 12.0793 25.0128 15.2302L24.0162 17.5002H33.3705C35.6392 17.5002 37.2283 19.7674 36.4788 21.9347L33.021 31.9347C32.5587 33.2715 31.3122 34.1669 29.9127 34.1669Z" stroke={`${liked ? '#0B74FA' : "#737377"}`} stroke-width="2.66667" stroke-linejoin="round"/>
            </svg>}
          </button>
        </div>
      </div>

      <div className="comments">
        <span className='importantText'>{blog.comments_count} {currentLang == 'en' ? 'comments' : 'تعليقا'}</span>
        {authCheck() && <div className="addAComment">
          {
          user?.user?.image ? <img src={user.user.image} alt=''/> :             
          <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7735 17.31C20.9093 17.3907 21.0768 17.4854 21.2666 17.5925C22.0982 18.0616 23.3553 18.7708 24.2166 19.6137C24.7552 20.1409 25.2669 20.8357 25.36 21.6869C25.4589 22.592 25.064 23.4414 24.2719 24.1961C22.9051 25.4981 21.265 26.5416 19.1437 26.5416H8.85573C6.73437 26.5416 5.09428 25.4981 3.7276 24.1961C2.93541 23.4414 2.54054 22.592 2.63948 21.6869C2.73252 20.8357 3.24428 20.1409 3.78289 19.6137C4.64409 18.7708 5.90118 18.0616 6.7328 17.5925C6.92257 17.4854 7.0902 17.3907 7.2259 17.31C11.3723 14.841 16.6272 14.841 20.7735 17.31Z" fill="white"/>
            <path d="M7.87476 7.58337C7.87476 4.20062 10.617 1.45837 13.9997 1.45837C17.3825 1.45837 20.1247 4.20062 20.1247 7.58337C20.1247 10.9661 17.3825 13.7084 13.9997 13.7084C10.617 13.7084 7.87476 10.9661 7.87476 7.58337Z" fill="white"/>
          </svg>
          }
          <textarea value={text} onChange={(e)=>handleInput(e)}  onKeyDown={(e) => handleKeyDown(e)} rows={1}
            name="addComment" id="addComment" placeholder={currentLang == 'en' ? 'Add a comment...' : "أضف تعليقا..."} className={`addACommentInput hideScrollBar`}
          ></textarea>
        </div>}
        {
          blog.comments.map((comment)=> <Comment user={comment.user} created={comment.time_since_created} comment={comment.comment}/>)
        }
      </div>
    </div>
  );
}

export default ReadMore;
