import { Link, NavLink, useNavigate } from 'react-router-dom';
import { DrawerProps } from '../../../models/AppBar'
import Logo from '../../sharedComponents/logo/Logo';
import './drawer.css'
import LanguageToggle from '../languageToggle/LanguageToggle';
import Location from '../location/Location';
import person from '../../../assets/pics/appBar/person.svg'
import { authCheck, getLang } from '../../../utils/functions/authCheck';
import { resetUserState } from '../../../RTK/features/userSlice/userSlice';
import { useAppDispatch } from '../../../RTK/hooks/hooks';
import lang from '../../../utils/lang/lang';

function Drawer(props: DrawerProps) {
    const {open, toggle} = props, navigate = useNavigate();
    const dispatch = useAppDispatch()
    const drawerListStyles = !open ? 'drawback' :'', togglerStyles = open ? 'bg-opacity-10' : 'bg-opacity-0', currentLang = getLang()
    const links = lang[currentLang].navigation;


    return (
    <div className={`drawer ${!open ? 'pointer-events-none' :''}`}>
        <div className={`drawerList ${drawerListStyles}`}>
            <div className='paddingoWra'>
                <Logo color='white'/>
            </div>
            <ul className='drawerRoutes'>
                {links.map((link, index) => (
                    <li key={index}>
                        <NavLink 
                            className={({ isActive }) => (isActive ? 'activeLink' : '')}
                            onClick={toggle}
                            to={link === "Homepage"|| lang['en'].navigation[index] === "Homepage"? "/" : `/${lang['en'].navigation[index].replace(/\s+/g, '').toLowerCase()}`}
                        >
                        {link}
                        </NavLink>
                    </li>
                ))}
                <div className='bottomBorder'></div>
            </ul>
            <div className="paddingoWra">
                <LanguageToggle/>
            </div>
            <div className="locationWra">
                <Location/>
            </div>
            <div className='loggedInRoute'>
                <img src={person} alt="person" />
                <Link to="profile">{currentLang === 'en' ? 'Profile' : 'الملف الشخصي'}</Link>
            </div>
            {authCheck() ? 
            <button className='drawerLogoutButton'
                onClick={() => {
                    navigate('/login')
                    dispatch(resetUserState())
                    localStorage.removeItem('authToken')
                    sessionStorage.removeItem('authToken')
                }}>
                {currentLang === 'en' ? 'Logout' : 'تسجيل الخروج'}
            </button> : <Link to='./login' className='drawerLoginButton'>{currentLang === 'en' ? 'Login' : 'تسجيل الدخول'}</Link>}
        </div>
        <div className={`toggler bg-black ${togglerStyles}`} onClick={toggle}></div>
    </div>
  )
}

export default Drawer

/**
 right above authCheck() :
    <div className='loggedInRoute'>
        <img src={job} alt="job" />
        <Link to="applyforajob">Apply for a job</Link>
    </div>
 */