import { DepartmentInfoProps } from '../../../models/applyForm'
import { getLang } from '../../../utils/functions/authCheck';
import lang from '../../../utils/lang/lang';
import './departmentInfo.css'

function DepartmentInfo(props: DepartmentInfoProps) {
    const {data} = props, currentLnag = getLang(), {jobForm} = lang[currentLnag];

  return (
    <div className="departmentInfo">
        <div className="blueCon headInfo">
            <div className='jobHeadlines'>
                <span className='importantText'>{jobForm.description[0]}</span>
                <span className='importantText'>{data.department.name}</span>
            </div>
            <div className='jobHeadlines'>
                <span className='importantText'>{jobForm.description[1]}</span>
                <span className='importantText'>{data.city.name}</span>
            </div>
        </div>
        <div className="blueCon">
        <span className='importantText'>{jobForm.description[2]}</span>
        <ol>
            {data.duties.map((duty, i)=> <li key={i}>{duty}</li>)}
        </ol>
        </div>
        <div className="blueCon requirments">
        <div>
            <span>{jobForm.description[3]}</span>
            <p>{data.education}</p>
        </div>
        <div>
            <span>{jobForm.description[4]}</span>
            <p>{data.skills.join(', ')}</p>
        </div>
        <div>
            <span>{jobForm.description[5]}</span>
            <p>{data.experience}</p>
        </div>
        </div>
    </div>
  )
}

export default DepartmentInfo