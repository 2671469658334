import ServiceCard from '../../sharedComponents/serviceCard/ServiceCard'
import './ourServices.css'
import { useEffect, useState } from 'react'
import { fetchData } from '../../../api/crudServices'
import { ServiceCardType } from '../../../models/homepage'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'

function OurServices() {
  const[departments, setDepartments] = useState<ServiceCardType[]>([])
  const currentLang = getLang(), {ourDepartments} = lang[currentLang].home
  useEffect(()=>{
    async function fetchDepartments(){
        const res = await fetchData('user/department/index')
        setDepartments(res.data)
    }
    fetchDepartments()
  }, [])

  return (
    <section className='ourServicesSec' id='ourServices'>
        <h3 className='blueBox'>{ourDepartments.label}</h3>
        <div className='centeredText'>
            <h2 className='header2'>{ourDepartments.title}</h2>
            <p className='secIntro'>{ourDepartments.smallDescription}</p>
        </div>
        <div className='cardsWra'>
            {
                departments.map((dep) => <ServiceCard key={dep.id} title={dep.name} des={dep.description} buttonContent={ourDepartments.button} image={dep.image} to={`/ourservices/${dep.id}`}/>)
            }
        </div>
    </section>
  )
}

export default OurServices