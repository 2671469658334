import React from 'react'
import doctor from '../../../assets/pics/homepage/doctor.webp'
import shield from '../../../assets/pics/homepage/shield.svg'
import plus from '../../../assets/pics/homepage/plus.svg'
import './ourVision.css'
import { Link } from 'react-router-dom'
import { getLang } from '../../../utils/functions/authCheck'
import lang from '../../../utils/lang/lang'

function OurVision() {
    const currentLang = getLang(), {ourVision} = lang[currentLang].home
    function navigate (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        const targetElement = document.getElementById('ourServices');
        if (targetElement) {
            const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
              top: elementPosition - 100,
              behavior: 'smooth',
            });
        }
    }
      
  return (
    <div className='ourVision paper'>
        <img src={doctor} alt='doctor' className='doctorIm'/>
        <div className='ourVisionText'>
            <h3 className='blueBox'>{ourVision.label}</h3>
            <div>
                <h2 className='header2'>{ourVision.title}</h2>
                <p className='secIntro'>{ourVision.smallDescription}</p>
            </div>
            <div className='ourVisionSecs'>
                <div className='ourVisionSec'>
                    <img className='ourVisionIcon' alt="ic" src={shield}/>
                    <span className='secondaryTitle'>{ourVision.sec1.title}</span>                    
                    <p>{ourVision.sec1.des}</p>
                </div>
                <div className='ourVisionSec'>
                    <img className='ourVisionIcon' alt="ic" src={plus}/>
                    <span className='secondaryTitle'>{ourVision.sec2.title}</span>
                    <p>{ourVision.sec2.des}</p>
                </div>
            </div>
            <Link to="#ourServices" onClick={(e) => navigate(e)} className='bgBlue seeServices'>{ourVision.button}</Link>
        </div>
    </div>
  )
}

export default OurVision