export function authCheck(){
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
    return !!token
}

export function getLang(){
    const lang = localStorage.getItem('lang');
    if(lang == 'ar')
        return lang
    return 'en'
}