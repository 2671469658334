import React, { useState } from 'react';
import formDoctor from '../../../assets/pics/profile/formDoctor.webp';
import './complain.css';
import toast from 'react-hot-toast';
import { postData } from '../../../api/crudServices';
import { getLang } from '../../../utils/functions/authCheck';
import lang from '../../../utils/lang/lang';

function Complain() {
  const [formData, setFormData] = useState({name: '', email: '', phone: '', complaint: ''});
  const [submitting, setSubmitting] = useState(false);
  const isFormValid = !(formData.complaint && formData.email && formData.name && formData.phone) || submitting;
  const currentLang = getLang(), {form} = lang[currentLang].profile

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'phone' && !/^\+?\d*$/.test(value))
        return;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try{
      setSubmitting(true)
      await postData('user/complaint/store', {message: formData.complaint})
      toast.success('Your complaint has been successfully sent', {style:{
        maxWidth: 'none'
      }})
      setFormData({name: '', email: '', phone: '', complaint: ''})
    }
    catch(err){
      console.error(err)
      toast.error('An error occurred, try again.')
    }
    finally{
      setSubmitting(false)
    }
  };

  return (
    <div className="complain">
      <img src={formDoctor} alt="formPic" />
      <form className="formWra" onSubmit={handleSubmit}>
        <h2 className="complainHeader2">
          {form.title} <span>{form.coloredTitle}</span>
        </h2>
        <input
          type="text"
          name="name"
          id="name"
          placeholder={form.formPlaceholders[0]}
          value={formData.name}
          onChange={handleInputChange}
        />
        <input
          type="email"
          name="email"
          id="email"
          placeholder={form.formPlaceholders[1]}
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder={form.formPlaceholders[2]}
          value={formData.phone}
          onChange={handleInputChange}
        />
        <textarea
          name="complaint"
          id="complaint"
          placeholder={form.formPlaceholders[3]}
          value={formData.complaint}
          onChange={handleInputChange}
        />
        <button type="submit" disabled={isFormValid}
            title={isFormValid ? 'Please fill out the entire form before submitting.' : ''}
        >{form.button}</button>
      </form>
    </div>
  );
}

export default Complain;
