import Select, { StylesConfig } from 'react-select';
import { GenderSelectProps } from '../../../../models/applyForm';
import { getLang } from '../../../../utils/functions/authCheck';

function GenderSelect(props: GenderSelectProps) {
  const { formData, handleChange } = props;
  const dodgerBlue = '#5CA2FB',  lightGray = '#F2F2F2', currentLang = getLang();
  
  const options = [{label:currentLang == 'en' ? 'Male': 'ذكر',value:'0'},{label:currentLang == 'en' ? 'Female': 'أنثلى',value:'1'}]

  const customStyles: StylesConfig<{label: string; value: string;}> = {
    control: (provided) => ({
      ...provided,
      border: '1px solid black',
      borderRadius: '8px',
      outline: 'black',
      boxShadow: 'none',
      width: '100%',
      paddingTop: '2px',
      paddingBottom: '2px',
      fontSize: '16px',
      marginBottom:'24px',
      ':hover':{
        border: '1px solid black',
      },
      ':focus-within':{
        outline: '1px solid black'
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: lightGray,
      marginTop: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? dodgerBlue : 'transparent',
      color: state.isSelected || state.isFocused ? 'white' : '#16495f',
      padding: '10px 15px',
      fontSize: '16px',
      ':active': {
        backgroundColor: `${dodgerBlue}`,
        color:'white'
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#011329',
      cursor: 'pointer',
      paddingRight: '4px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '150px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  };

  const handleSelectChange = (selectedOption: {label: string; value: string;}) => {
    if (selectedOption) {
      handleChange({ name:'gender' , value: selectedOption.value });
    }
  };

  return (
      <Select
        options={options}
        name='gender'
        id='gender'
        placeholder={getLang() == 'en' ? 'Select...' : 'اختر...'}
        styles={customStyles}
        isSearchable={true}
        value={options.find(option => option.value === formData['gender']) || null}
        onChange={(selected)=>handleSelectChange(selected as {label: string; value: string;})}
      />
  );
}

export default GenderSelect;
