import toast from 'react-hot-toast'
import GenderSelect from '../nestedComponents/selects/GenderSelects';
import { useRef, useState } from 'react';
import dropFile from '../../../assets/pics/applyForm/dropFile.svg'
import './jobForm.css'
import { useParams } from 'react-router-dom';
import { postData } from '../../../api/crudServices';
import lang from '../../../utils/lang/lang';
import { getLang } from '../../../utils/functions/authCheck';

function JobForm() {
    const {id} = useParams()
    const [formData, setFormData] = useState<{cv:File|null; gender:string; [key:string]: any}>({ name: '', phone: '', email: '', gender: '', job_position_id: id, cv: null, description: '' });
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const currentLnag = getLang(), {jobForm} = lang[currentLnag];
    
    function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | {name:string; value:string}) {
      const { name, value } = 'target' in e ? e.target : e;
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
      const file = e.target.files?.[0] || null;
      setFormData((prev) => ({ ...prev, cv: file }));
    }
    
    function handleDrop(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      const file = e.dataTransfer.files?.[0];
    if (file) {
        setFormData((prev) => ({ ...prev, cv: file }));
      }
    }

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
      setLoading(true)
      try {        
        await postData('user/employment/store', formData, true)        
        toast.success('We recieved your application', { duration: 3000 });
        setFormData({ name: '', phone: '', email: '', gender: '', job_position_id: id, cv: null, description: '' })
      } catch (err) {
        console.error('Submission error:', err);
        toast.error('Error', { duration: 3000 });
      }finally{
        setLoading(false)
      }
    }
    
  return (
    <form className="jobForm" onSubmit={handleSubmit}>
        <label htmlFor="name">{jobForm.form[0]}</label>
        <input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange}/>
        <label htmlFor="phone">{jobForm.form[1]}</label>
        <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleInputChange}/>
        <label htmlFor="email">{jobForm.form[2]}</label>
        <input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange}/>
        <label htmlFor="gender">{jobForm.form[3]}</label>
        <GenderSelect formData={formData} handleChange={handleInputChange}/>
        <label htmlFor="description">{jobForm.form[4]}</label>
        <textarea name="description" id="description" rows={1} value={formData.description} onChange={handleInputChange} className="hideScrollBar"/>
        <label htmlFor="cv">{jobForm.form[5]}</label>
        <div className="uploadWra"
            onClick={() => fileInputRef.current?.click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input ref={fileInputRef} type="file" id="cv" name="cv" onChange={handleFileChange} accept=".pdf,.jpg,.png,.jpeg"/>
            <img src={dropFile} alt="dropFile"/>
            <p>{formData.cv ? formData.cv.name : jobForm.fileInput}</p>
        </div>
        <button type="submit" disabled={!(formData.cv && formData.description && formData.email && formData.gender && formData.name && formData.phone && formData.job_position_id) || loading}>{jobForm.button}</button>
    </form>
  );
}
    
    export default JobForm;